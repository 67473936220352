import { Button, Grid, Group, Paper, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { collection, doc, getDoc } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthContext } from '../../contexts/AuthContext';
import { db } from '../../firebase';

interface IStudent {
  id?: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  homeRoom?: {
    id?: string;
    name: string;
    number?: number;
  };
}
const PageWrapper = styled.div`
  flex-grow: 1;
`;

const PageContent = styled(Paper)`
  flex-grow: 1;
  padding: 1rem;
  border: 1px solid #ddd;
`;

const StudentsDetailPage = () => {
  const navigate = useNavigate();
  const studentsCollectionRef = collection(db, 'students');
  let { studentKey } = useParams();
  const [student, setStudent] = useState<IStudent>({ id: '', firstName: '', lastName: '', email: '' });
  const { adminCreateUser } = useAuthContext();

  const handleDeleteStudent = () => {
    console.log('Delete student');
    navigate('/students');
  };

  const form = useForm<IStudent>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  useEffect(() => {
    const docRef = doc(studentsCollectionRef, studentKey);
    getDoc(docRef).then(doc => {
      console.log('getting student data');
      if (doc.exists()) {
        console.log('student exists!');
        setStudent({
          id: doc.id,
          firstName: doc.data().firstName || '',
          lastName: doc.data().lastName || '',
          email: doc.data().email || '',
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentKey]);

  useMemo(() => {
    console.log('setting student data');
    form.setValues({ firstName: student?.firstName, lastName: student?.lastName, email: student?.email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  const createUser = (student: IStudent) => {
    console.log(`Now we will create a user account for ${student.firstName}`);

    // setLoading(true);
    if (student.email) {
      adminCreateUser(student.email);
    } else console.log('An email address is required to create a user account!');

    // setLoading(false);
  };

  return (
    <PageWrapper>
      <h2>Student Details</h2>
      <PageContent>
        <form onSubmit={form.onSubmit(values => console.log(values))}>
          <Grid>
            <Grid.Col md={6} sm={12}>
              <TextInput label="First name" placeholder="First name" {...form.getInputProps('firstName')} />
            </Grid.Col>
            <Grid.Col md={6} sm={12}>
              <TextInput
                name="lastName"
                label="Last name"
                placeholder="Last name"
                {...form.getInputProps('lastName')}
              />
            </Grid.Col>
          </Grid>
          <TextInput name="email" label="email" placeholder="Email" {...form.getInputProps('email')} />

          <Group position="apart">
            <Button variant="outline" color="red" onClick={handleDeleteStudent}>
              Delete student
            </Button>
            <Group spacing="md">
              <Button type="submit">Save student</Button>
              <Button onClick={() => createUser(student)}>
                <FaUser size="16" /> Create User Account
              </Button>
            </Group>
          </Group>
        </form>
      </PageContent>
    </PageWrapper>
  );
};

export default StudentsDetailPage;
