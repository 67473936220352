import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { useAuthContext } from '../../contexts/AuthContext';
import { Alert, Button, Card, PasswordInput, TextInput } from '@mantine/core';

const AuthPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: bottom;
  // height: 100vh;
  margin-top: 200px;
  //width: 100%;
  padding: 0 20px;
`;

const AuthPageInner = styled.div`
  margin: auto;
  width: 100%;
  max-width: 400px;
  min-width: 250px;
  :before {
    content: '';
    position: absolute;
    z-index: -1;
    height: 350px;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #228be6;
  }
`;

const H2 = styled.h2`
  color: #fff;
`;

const LoginAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

const LoginEmail = styled(TextInput)`
  margin-bottom: 1rem;
`;

const LoginPassword = styled(PasswordInput)`
  margin-bottom: 1rem;
`;

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, authError } = useAuthContext();

  const handleLoginValueChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.currentTarget.id === 'email') {
      setEmail(event?.currentTarget?.value);
    } else if (event?.currentTarget.id === 'password') {
      setPassword(event?.currentTarget?.value);
    } else {
      return;
    }
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    setLoading(true);
    login(email, password);
    setLoading(false);
  };

  return (
    <>
      <AuthPageWrapper>
        <AuthPageInner>
          <H2>Log In</H2>
          {authError && <LoginAlert color="red">{JSON.stringify(authError.message)}</LoginAlert>}

          <form onSubmit={handleLogin}>
            <Card p="sm" mb="md" withBorder>
              <LoginEmail
                label="Email"
                type="email"
                placeholder="Enter email"
                autoComplete="false"
                required
                id="email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleLoginValueChanged(e)}
              />
              <LoginPassword
                label="Password"
                placeholder="Password"
                autoComplete="false"
                required
                id="password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleLoginValueChanged(e)}
              />
              <Button
                type="submit"
                color="primary"
                variant="filled"
                fullWidth
                disabled={loading || !email || !password}
              >
                Log In
              </Button>
            </Card>
          </form>
        </AuthPageInner>
      </AuthPageWrapper>
    </>
  );
};

export default LoginPage;
