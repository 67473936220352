import { Composition } from 'atomic-layout';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1320px;
  width: 100%;
  overflow-x: auto;
`;

const areasMobile = `
  header
  content
`;

const areasDesktop = `
header
content
`;

const AdminPageLayout = () => {
  return (
    <>
      <Composition areas={areasMobile} areasMd={areasDesktop} templateCols="auto" templateRows="auto">
        {Areas => (
          <>
            <Areas.Content>
              <ContentWrapper>
                <Outlet />
              </ContentWrapper>
            </Areas.Content>
          </>
        )}
      </Composition>
    </>
  );
};

export default AdminPageLayout;
