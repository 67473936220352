import { Box, Button, Checkbox, Group, TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const StudentsDetailPage = () => {
  const navigate = useNavigate();
  const studentsCollectionRef = collection(db, 'students');

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      termsOfService: false,
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  const handleDeleteStudent = () => {
    console.log('Delete student');
    navigate('/students');
  };

  const handleSaveStudent = async (values: {}, studentId?: string) => {
    console.log('Save student');
    console.log(values);
    if (studentId) {
      const studentRef = doc(db, 'students', studentId);
      console.log(studentRef);
      await updateDoc(studentRef, {
        homeRoom: {
          name: 'test Homeroom',
          number: 1,
        },
      });
      console.log(`Student being updated: ${studentId}`);
    } else {
      await addDoc(studentsCollectionRef, values);
    }
    // navigate('/students');
  };

  return (
    <div>
      <h2>Add or Edit Student Details</h2>
      <Box sx={{ maxWidth: 300 }}>
        <form onSubmit={form.onSubmit((values: {}) => handleSaveStudent(values))}>
          <TextInput required label="First Name" placeholder="First Name" {...form.getInputProps('firstName')} />
          <TextInput required label="Last Name" placeholder="Last Name" {...form.getInputProps('lastName')} />

          <TextInput required label="Email" placeholder="your@email.com" {...form.getInputProps('email')} />

          <Checkbox
            mt="md"
            label="I agree to sell my privacy"
            {...form.getInputProps('termsOfService', { type: 'checkbox' })}
          />

          <Group position="right" mt="md">
            <Button type="submit">Save student</Button>
            <Button onClick={handleDeleteStudent}>Delete student</Button>
          </Group>
        </form>
      </Box>
    </div>
  );
};

export default StudentsDetailPage;
