import { Avatar, Badge, Button, Container, Divider, Group, Paper, TextInput } from '@mantine/core';
import { useAuthContext } from '../contexts/AuthContext';
// import { useNotifications } from '@mantine/notifications';
import { ChangeEvent, useMemo, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { updateProfile } from 'firebase/auth';

const ProfilePage = () => {
  const { currentUser, updateUserClaim, isAdmin, isWhanau, userRoles } = useAuthContext();
  const [displayName, setDisplayName] = useState(currentUser?.displayName?.toString());
  const [photoURL, setPhotoURL] = useState(currentUser?.photoURL?.toString());
  const [roles, setRoles] = useState<string[]>(userRoles);
  // https://avatars.githubusercontent.com/u/1541741?s=60&v=4

  // const notifications = useNotifications();

  let avatarURL = '';
  if (currentUser?.photoURL) {
    avatarURL = currentUser?.photoURL;
  }
  useMemo(() => {
    setRoles(userRoles);
  }, [userRoles]);

  // useMemo(() => {
  //   const userRoles = [];
  //   if (isAdmin) userRoles.push('admin');
  //   if (isFaculty) userRoles.push('faculty');
  //   if (isWhanau) userRoles.push('whanau');
  //   if (isStudent) userRoles.push('student');
  //   setRoles(userRoles);
  // }, [isAdmin, isFaculty, isStudent, isWhanau]);

  const handleUpdateProfile = async (e: any) => {
    e.preventDefault();
    if (currentUser)
      await updateProfile(currentUser, { displayName: displayName, photoURL: photoURL }).catch(e => {
        console.log(e);
      });
  };

  // const handleLogout = async () => {
  //   await logout();
  // };

  const handleProfileValueChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.currentTarget.id === 'display-name') {
      setDisplayName(event?.currentTarget?.value);
    } else if (event?.currentTarget.id === 'photo-url') {
      setPhotoURL(event?.currentTarget?.value);
    } else {
      return;
    }
  };

  const handleRoleUpdate = async (role: string) => {
    console.log(`Add ${role} role!`);
    if (currentUser) {
      if (role === 'admin') await updateUserClaim(currentUser?.uid, { admin: true });
      if (role === 'faculty') await updateUserClaim(currentUser?.uid, { faculty: true });
      if (role === 'student') await updateUserClaim(currentUser?.uid, { student: true });
      if (role === 'whanau') await updateUserClaim(currentUser?.uid, { whanau: true });
    }
  };

  // const myRoles = userRoles?.map((role: string) => <li>{role}</li>);

  return (
    <Container>
      <h1>Profile</h1>

      <form onSubmit={handleUpdateProfile}>
        <Paper p="md" mb="lg" shadow="xs" withBorder>
          <Group>
            <Avatar size={100} radius={100} src={avatarURL} />
            <div>
              <h1>
                <span>Welcome </span>
                {currentUser?.displayName ? currentUser?.displayName : currentUser?.email}
              </h1>
              <div>
                <strong>Email:</strong> {currentUser?.email}{' '}
                {currentUser?.emailVerified && <Badge color="green">Email Verified</Badge>}
                {isAdmin && <Badge color="blue">Admin</Badge>}
              </div>
              <div>Display Name: {currentUser?.displayName}</div>
            </div>
          </Group>
        </Paper>
        <Paper p="md" mb="lg" shadow="xs" withBorder>
          {currentUser?.emailVerified && (
            <p>
              <strong>Please complete your profile details and verify your email address</strong>
            </p>
          )}

          <TextInput
            label="Display name"
            placeholder="Enter your prefered name"
            autoComplete="false"
            value={displayName}
            required
            id="display-name"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleProfileValueChanged(e)}
          />
          <br />

          <TextInput
            label="Photo URL"
            placeholder="Enter a link to your profile image"
            description="For example your github profile image."
            autoComplete="false"
            value={photoURL}
            required
            id="photo-url"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleProfileValueChanged(e)}
          />

          <Divider variant="dotted" />

          <Checkbox.Group
            label="Role"
            description="What role do you fill?"
            orientation="vertical"
            value={roles}
            onChange={setRoles}
          >
            <Checkbox value="admin" label="Admin" />
            <Checkbox value="faculty" label="Faculty" />
            <Checkbox value="whanau" label="Whanaū" />
            <Checkbox value="student" label="Student" />
          </Checkbox.Group>
        </Paper>

        <Group position="apart">
          <Group>
            {!isAdmin && (
              <Button variant="default" onClick={() => handleRoleUpdate('admin')}>
                Make me an admin!
              </Button>
            )}
            {!isWhanau && (
              <Button variant="default" onClick={() => handleRoleUpdate('whanau')}>
                I'm Whanau!
              </Button>
            )}
          </Group>
          <Button variant="filled" type="submit">
            Update my profile
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default ProfilePage;
