import { Card, Text, Badge, Group, Button, Title } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

const DashboardPage = () => {
  const { currentUser, myToken } = useAuthContext();
  const navigate = useNavigate();
  const clipboard = useClipboard();

  return (
    <>
      <Group position="apart">
        <h2>Hi {currentUser?.displayName}</h2>
        <Button color={clipboard.copied ? 'teal' : 'blue'} onClick={() => clipboard.copy(myToken)}>
          {clipboard.copied ? 'Token Copied' : 'Copy Token'}
        </Button>
      </Group>
      <Group grow>
        <Card p="md" withBorder>
          <Card.Section p="md" sx={{ backgroundColor: 'lightgrey' }}>
            <Group position="apart">
              <Title order={4} color="dark">
                Students
              </Title>
              <Badge variant="outline" color="white">
                9
              </Badge>
            </Group>
          </Card.Section>
          <Text size="sm" my="md" color="dimmed">
            Looks like you have some students... yay!
          </Text>

          <Button variant="outline" fullWidth mt="md" radius="md" onClick={() => navigate('/admin/students')}>
            Manage Students
          </Button>
        </Card>
        <Card p="md" withBorder>
          <Card.Section p="md" sx={{ backgroundColor: 'lightgrey' }}>
            <Group position="apart">
              <Title order={4} color="dark">
                Classes
              </Title>
              <Badge variant="outline" color="white">
                2
              </Badge>
            </Group>
          </Card.Section>
          <Text size="sm" my="md" color="dimmed">
            Looks like you have some classes... yay!
          </Text>

          <Button variant="outline" fullWidth mt="md" radius="md" onClick={() => navigate('/admin/classes')}>
            Manage Classes
          </Button>
        </Card>
        <Card p="md" withBorder>
          <Card.Section p="md" sx={{ backgroundColor: 'lightgrey' }}>
            <Group position="apart">
              <Title order={4} color="dark">
                Rooms
              </Title>
              <Badge variant="outline" color="white">
                2
              </Badge>
            </Group>
          </Card.Section>
          <Text size="sm" my="md" color="dimmed">
            Looks like you have some rooms... yay!
          </Text>

          <Button variant="outline" fullWidth mt="md" radius="md" onClick={() => navigate('/admin/rooms')}>
            Manage Rooms
          </Button>
        </Card>
      </Group>
    </>
  );
};

export default DashboardPage;
