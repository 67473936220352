import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FaHome, FaUsers } from 'react-icons/fa';

const NavigationWrapper = styled.section`
  position: fixed;
  overflow-x: scroll;
  height: calc(100vh - 44px);
  width: 180px;
`;

const NavItems = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-right: 1px solid #ddd;
  min-height: calc(100vh - 44px);
`;

const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  color: #333;
  border-color: transparent;
  border-width: 1px 0;
  border-style: solid;
  &.active {
    background: linear-gradient(90deg, #fff 0%, #f9f9f9 100%);
    border-image: linear-gradient(to right, #fff, #ddd) 2;
    color: #000;
    font-weight: bold;
    margin-right: -1px;
  }
`;

const NavIcon = styled.span`
  margin-right: 0.5rem;
`;

const Sidebar: FC = () => (
  <NavigationWrapper>
    <NavItems>
      <h3>Admin</h3>
      <NavItem to="/admin/students">
        <NavIcon>
          <FaUsers />
        </NavIcon>
        Students
      </NavItem>
      <NavItem to="/admin/classes">
        <NavIcon>
          <FaHome />
        </NavIcon>
        Classes
      </NavItem>
    </NavItems>
  </NavigationWrapper>
);

export default Sidebar;
