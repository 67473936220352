import { FC, StrictMode, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { NotificationsProvider } from '@mantine/notifications';
import './App.scss';

import { AppRoutes } from './AppRoutes';
import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { AuthContextProvider } from './contexts/AuthContext';
import { useColorScheme } from '@mantine/hooks';

export const App: FC = () => {
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useState<ColorScheme>(preferredColorScheme);
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  return (
    <StrictMode>
      <AuthContextProvider>
        <Router>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider>
              <NotificationsProvider>
                <AppRoutes />
              </NotificationsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </Router>
      </AuthContextProvider>
    </StrictMode>
  );
};

export default App;
