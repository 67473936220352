import { useAuthContext } from '../../contexts/AuthContext';
import {
  createStyles,
  Avatar,
  Divider,
  Group,
  Menu,
  Text,
  UnstyledButton,
  useMantineTheme,
  Burger,
  Container,
  Badge,
  Center,
  Stack,
} from '@mantine/core';
import { useMemo, useState } from 'react';
import { useDisclosure, useIdle } from '@mantine/hooks';
import { Logout, ChevronDown, User, Users, Home } from 'tabler-icons-react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';

const useStyles = createStyles(theme => ({
  header: {
    backgroundColor: theme.colors[theme.primaryColor][6],
    borderBottom: `1px solid ${theme.colors[theme.primaryColor][6]}`,
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    zIndex: 50,
  },

  mainSection: {
    paddingBottom: theme.spacing.sm,
  },

  userMenu: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  user: {
    color: theme.white,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5],
    },
  },

  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  userActive: {
    backgroundColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5],
  },

  tabs: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  tabsList: {
    borderBottom: '0 !important',
  },

  tabControl: {
    fontWeight: 500,
    height: 38,
    color: `${theme.white} !important`,

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5],
    },
  },

  tabControlActive: {
    color: `${theme.colorScheme === 'dark' ? theme.white : theme.black} !important`,
    borderColor: `${theme.colors[theme.primaryColor][6]} !important`,
  },
}));

const Brand = styled(Link)`
  font-size: 1.5rem;
  font-weight: 200;
  padding: 0.5rem 0;
  text-decoration: none;
  color: #fff;
  > span {
    font-weight: 700;
  }
`;

export const Header = () => {
  const idleTimeValue = 900000;
  const theme = useMantineTheme();
  const { logout, currentUser, isAdmin } = useAuthContext();
  const { classes, cx } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const navigate = useNavigate();

  let avatarURL = '';
  if (currentUser?.photoURL) {
    avatarURL = currentUser?.photoURL;
  }

  const handleLogout = async () => {
    await logout();
  };

  const idle = useIdle(idleTimeValue, { initialState: false, events: ['click', 'touchstart'] });

  useMemo(() => {
    console.log('useMemo Fired!');
    if (idle) {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idle]);

  return (
    <>
      <div className={classes.header}>
        <Container size="xl">
          <Group position="apart">
            <Group spacing={5}>
              <Brand to="/">
                School<span>Fusion</span>
              </Brand>
              {isAdmin && (
                <>
                  &nbsp;<Badge>ADMIN</Badge>
                </>
              )}
            </Group>
            <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" color={theme.white} />
            <Menu
              width={260}
              position="bottom-end"
              transition="pop-top-right"
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
            >
              <Menu.Target>
                <UnstyledButton className={cx(classes.user, { [classes.userActive]: userMenuOpened })}>
                  <Group spacing={7}>
                    <Avatar src={avatarURL} alt={currentUser?.displayName || 'user'} radius="xl" size={20} />
                    <Text weight={500} size="sm" sx={{ lineHeight: 1, color: theme.white }} mr={3}>
                      {currentUser?.displayName || 'user'}
                    </Text>
                    <ChevronDown size={12} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                {/* <Menu.Item icon={<Heart size={14} color={theme.colors.red[6]} />}>Liked posts</Menu.Item>
                <Menu.Item icon={<Star size={14} color={theme.colors.yellow[6]} />}>Saved posts</Menu.Item>
                <Menu.Item icon={<Message size={14} color={theme.colors.blue[6]} />}>Your comments</Menu.Item> */}

                <Stack justify="center">
                  <Container my="md">
                    <Avatar size={100} radius={100} src={avatarURL} mb="md" />

                    <Center>{currentUser?.displayName ? currentUser?.displayName : currentUser?.email}</Center>
                    {isAdmin && (
                      <Badge color="white" fullWidth>
                        Admin
                      </Badge>
                    )}
                  </Container>
                </Stack>
                <Divider />
                <Menu.Label>User</Menu.Label>
                <Menu.Item icon={<User size={14} />} onClick={() => navigate('/profile')}>
                  Profile
                </Menu.Item>
                {isAdmin && (
                  <>
                    <Divider />
                    <Menu.Label>Admin</Menu.Label>
                    <Menu.Item icon={<Users size={14} />} onClick={() => navigate('/admin/students')}>
                      Students
                    </Menu.Item>
                    <Menu.Item icon={<Home size={14} />} onClick={() => navigate('/admin/classes')}>
                      Classes
                    </Menu.Item>
                  </>
                )}
                <Divider />
                <Menu.Item color="red" icon={<Logout size={14} />} onClick={handleLogout}>
                  Logout
                </Menu.Item>

                {/* <Menu.Label>Danger zone</Menu.Label>
                <Menu.Item icon={<PlayerPause size={14} />}>Pause subscription</Menu.Item>
                <Menu.Item color="red" icon={<Trash size={14} />}>
                  Delete account
                </Menu.Item> */}
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Container>
      </div>
    </>
  );
};
