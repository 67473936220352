import { Outlet } from 'react-router-dom';
import { Button, Table, LoadingOverlay, Paper, Group } from '@mantine/core';
import { db } from '../firebase';
import { useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';

import styled from 'styled-components';
import Sidebar from '../components/sidebar/Sidebar';

interface IStudent {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  homeRoom?: {
    id?: string;
    name: string;
    number?: number;
  };
}

const PageOuter = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const PageNav = styled.div`
  width: 180px;
`;
const PageContent = styled.div`
  width: calc(100% - 212px);
  padding: 0 1rem;
  background: linear-gradient(90deg, #f9f9f9 0%, #fff 100%);
  min-height: calc(100vh - 44px);
`;

const Tr = styled.tr`
  :hover {
    cursor: pointer;
  }
`;

// interface IStudentClass {
//   className: string;
// }
interface IRoom {
  id: string;
  name: string;
  number: number;
}

const ClassesPage = () => {
  // const navigate = useNavigate();
  const [students, setStudents] = useState<IStudent[]>([]);
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [loading, setLoading] = useState(false);
  const studentsCollectionRef = collection(db, 'students');
  const roomCollectionRef = collection(db, 'room');

  // const students = fetch(
  //   `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/students`
  // ).then(response => console.log(response.get));

  useEffect(() => {
    // const studentClassesCollectionRef = collection(db, 'studentClasses');

    const student_q = query(studentsCollectionRef, orderBy('lastName'));
    const unsubStudents = onSnapshot(student_q, snapshot => {
      setStudents(
        snapshot.docs.map(doc => ({
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          homeRoom: doc.data().homeRoom,
        }))
      );
      setLoading(false);
      console.log(students);
    });

    const room_q = query(roomCollectionRef, orderBy('number'));
    const unsubRooms = onSnapshot(room_q, snapshot => {
      setRooms(snapshot.docs.map(doc => ({ name: doc.data().name, number: doc.data().number, id: doc.id })));
    });

    return () => {
      unsubStudents();
      unsubRooms();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const addHomeRoom = async (studentId: string | undefined) => {
  //   if (studentId) {
  //     const studentRef = doc(db, 'students', studentId);
  //     console.log(studentRef);
  //     await updateDoc(studentRef, {
  //       homeRoom: {
  //         name: 'test Homeroom',
  //         number: 1,
  //       },
  //     });
  //     console.log(`Student being updated: ${studentId}`);
  //   }
  // };

  // const removeHomeRoom = async (studentId: string | undefined) => {
  //   if (studentId) {
  //     const studentRef = doc(db, 'students', studentId);
  //     console.log(studentRef);
  //     await updateDoc(studentRef, {
  //       homeRoom: {},
  //     });
  //     console.log(`Student being updated: ${studentId}`);
  //   }
  // };

  // const handleAddStudent = () => {
  //   console.log('Add a new student');
  //   navigate('/students/add');
  // };

  // const handleAddTeacher = () => {
  //   console.log('Add a new teacher');
  //   // navigate('/staff/add');
  // };

  const handleAddClass = () => {
    console.log('Add a new class');
    // navigate('/classes/add');
  };

  // const handleViewClass = (id: string) => {
  //   console.log(`View class ${id}}`);
  //   // navigate('/classes/add');
  // };

  // const getStudentClasses = async (student: IStudent) => {
  //   const q = query(studentClassesCollectionRef, where('student', '==', student.id));

  //   const data = await getDocs(q);

  //   setStudentClasses(data.docs.map(doc => ({ className: doc.data().class, id: doc.id })));
  // };

  // const displayStudentClasses = studentClasses?.map(studentClass => (
  //   <tr>
  //     <td colSpan={3}>&nbsp;</td>
  //     <td>Class: {studentClass.className}</td>
  //   </tr>
  // ));

  // const roomListItems = () => {
  //   const roomList: { value: string; label: string }[] = [];
  //   rooms?.map((room: IRoom, index: number) => roomList.push({ value: room.id, label: room.name }));
  //   return roomList;
  // };

  // const HomeRoomSelector = () => (
  //   <Select
  //     label="Homeroom"
  //     placeholder="Select a homeroom"
  //     data={roomListItems()}
  //     size="sm"
  //     icon={<FaHouseUser size="16" />}
  //   />
  // );

  // const Classes = () => {
  //   rooms?.map(room => (
  //     <tr>
  //       <td>{room.name}</td>
  //     </tr>
  //   ));
  // };

  const rows = rooms?.map((room: IRoom, index: number) => (
    <Tr key={index} onClick={() => {}}>
      <td>{room.name}</td>
      <td>{room.number}</td>
      <td align="right"></td>
    </Tr>
  ));

  return (
    <>
      <PageOuter>
        <PageNav>
          <Sidebar />
        </PageNav>
        <PageContent>
          <Group position="apart">
            <h2>Classes</h2> <Button onClick={handleAddClass}>Add a new class</Button>
          </Group>
          <Paper shadow="xs" withBorder>
            <LoadingOverlay visible={loading} />
            <Table striped highlightOnHover verticalSpacing="md" horizontalSpacing="lg">
              <thead>
                <tr>
                  <th>Class name</th>
                  <th>Room number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </Paper>
          <Outlet />
        </PageContent>
      </PageOuter>
    </>
  );
};

export default ClassesPage;
