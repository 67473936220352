import { useNavigate, Outlet } from 'react-router-dom';
import { Button, Divider, Table, LoadingOverlay, Select, Paper, Group, UnstyledButton } from '@mantine/core';
import { db } from '../firebase';
import { useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { FaHouseUser, FaPen } from 'react-icons/fa';

import styled from 'styled-components';
import Sidebar from '../components/sidebar/Sidebar';

interface IStudent {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  homeRoom?: {
    id?: string;
    name: string;
    number?: number;
  };
}

const Tr = styled.tr`
  :hover {
    cursor: pointer;
  }
`;

interface IRoom {
  id: string;
  name: string;
  number: number;
}

const PageOuter = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const PageNav = styled.div`
  width: 180px;
`;
const PageContent = styled.div`
  width: calc(100% - 212px);
  padding: 0 1rem;
  background: linear-gradient(90deg, #f9f9f9 0%, #fff 100%);
  min-height: calc(100vh - 44px);
`;

const StudentsIndexPage = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState<IStudent[]>([]);
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [loading, setLoading] = useState(false);
  const studentsCollectionRef = collection(db, 'students');
  const roomCollectionRef = collection(db, 'room');

  useEffect(() => {
    // const studentClassesCollectionRef = collection(db, 'studentClasses');

    const student_q = query(studentsCollectionRef, orderBy('lastName'));
    const unsubStudents = onSnapshot(student_q, snapshot => {
      setStudents(
        snapshot.docs.map(doc => ({
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          homeRoom: doc.data().homeRoom,
        }))
      );
      setLoading(false);
    });

    const room_q = query(roomCollectionRef, orderBy('number'));
    const unsubRooms = onSnapshot(room_q, snapshot => {
      setRooms(snapshot.docs.map(doc => ({ name: doc.data().name, number: doc.data().number, id: doc.id })));
    });

    return () => {
      unsubStudents();
      unsubRooms();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddStudent = () => {
    console.log('Add a new student');
    navigate('add');
  };

  const handleEditStudent = (student: IStudent, e: any) => {
    e.stopPropagation();
    console.log('Edit student');
    navigate(`edit/${student.id}`);
  };

  const handleViewStudent = (student: IStudent) => {
    console.log('View student');
    navigate(`view/${student.id}`);
  };

  const rows = students?.map((student: IStudent, index: number) => (
    <Tr key={index} onClick={() => handleViewStudent(student)}>
      <td>{student.firstName}</td>
      <td>{student.lastName}</td>
      <td>{student.id}</td>
      <td>{student.email}</td>
      <td>{student.homeRoom?.name}</td>
      <td align="right">
        {student.email && (
          <>
            <UnstyledButton
              onClick={(e: any) => {
                handleEditStudent(student, e);
              }}
            >
              <FaPen size="16" />
            </UnstyledButton>
          </>
        )}
      </td>
    </Tr>
  ));

  const roomListItems = () => {
    const roomList: { value: string; label: string }[] = [];
    rooms?.map((room: IRoom, index: number) => roomList.push({ value: room.id, label: room.name }));
    return roomList;
  };

  const HomeRoomSelector = () => (
    <Select
      label="Homeroom"
      placeholder="Select a homeroom"
      data={roomListItems()}
      size="sm"
      icon={<FaHouseUser size="16" />}
    />
  );

  return (
    <>
      <PageOuter>
        <PageNav>
          <Sidebar />
        </PageNav>
        <PageContent>
          <Group position="apart">
            <h2>Students</h2> <Button onClick={handleAddStudent}>Add a new student</Button>
          </Group>
          <Paper shadow="xs" withBorder>
            <LoadingOverlay visible={loading} />
            <Table striped highlightOnHover verticalSpacing="md" horizontalSpacing="lg">
              <thead>
                <tr>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>StudentID</th>
                  <th>Email</th>
                  <th>Home Room</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </Paper>
          <Paper shadow="xs" p="sm" withBorder mt="lg">
            <h2>Rooms</h2>
            <HomeRoomSelector />
            <Divider variant="dotted" />
          </Paper>
          <Outlet />
        </PageContent>
      </PageOuter>
    </>
  );
};

export default StudentsIndexPage;
