import { Navigate, useRoutes } from 'react-router-dom';

import PageLayout from './pages/PageLayout';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';

import { Error404Page } from './pages/Error404Page';
import LoginPage from './pages/auth/LoginPage';
import { useAuthContext } from './contexts/AuthContext';
import OnboardingPage from './pages/auth/OnboardingPage';
import StudentsPage from './pages/StudentsPage';
import StudentDetail from './components/students/StudentDetail';
import StudentForm from './components/students/StudentForm';
import AdminPageLayout from './pages/AdminPageLayout';
import ClassesPage from './pages/Classes';

export const AppRoutes = () => {
  const { currentUser, isAdmin } = useAuthContext();

  let isEmailVerified = false;
  if (currentUser) {
    isEmailVerified = currentUser?.emailVerified;
  }
  const isLoggedIn = currentUser;

  return useRoutes([
    {
      path: 'dashboard',
      element: isLoggedIn ? <PageLayout /> : <LoginPage />,
      children: [
        {
          path: '',
          element: isEmailVerified ? <DashboardPage /> : <Navigate to="/onboarding" />,
        },
      ],
    },
    {
      path: 'admin',
      element: isLoggedIn ? isAdmin ? <PageLayout /> : <Error404Page /> : <LoginPage />,
      children: [
        {
          path: 'students',
          element: <AdminPageLayout />,
          children: [
            { path: '', element: <StudentsPage /> },
            {
              path: 'edit/:studentKey',
              element: <StudentForm />,
            },
            {
              path: 'view/:studentKey',
              element: <StudentDetail />,
            },
            {
              path: 'add',
              element: <StudentForm />,
            },
          ],
        },
        {
          path: 'classes',
          element: <AdminPageLayout />,
          children: [
            { path: '', element: <ClassesPage /> },
            {
              path: 'edit/:studentKey',
              element: <StudentForm />,
            },
            {
              path: 'view/:studentKey',
              element: <StudentDetail />,
            },
            {
              path: 'add',
              element: <StudentForm />,
            },
          ],
        },
      ],
    },
    {
      path: 'profile',
      element: isLoggedIn ? <PageLayout /> : <LoginPage />,
      children: [
        {
          path: '',
          element: <ProfilePage />,
        },
      ],
    },
    {
      path: 'login',
      element: !isLoggedIn ? <LoginPage /> : <Navigate to="/dashboard" />,
    },
    {
      path: 'onboarding',
      element: isEmailVerified === false && isLoggedIn ? <OnboardingPage /> : <Navigate to="/dashboard" />,
    },
    {
      path: '*',
      element: <Error404Page />,
    },
    { path: '', element: <Navigate to="dashboard" /> },
  ]);
};
