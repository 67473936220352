import { Avatar, Badge, Button, Container, Divider, TextInput } from '@mantine/core';
import styled from 'styled-components';
import { useAuthContext } from '../../contexts/AuthContext';
import { ChangeEvent, useState } from 'react';
import { sendEmailVerification, updateProfile } from 'firebase/auth';

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProfileHeader = styled.div`
  display: flex;
  justify-content: start;
  background-color: teal;
  padding: 1rem;
  color: #fff;
  border-radius: 0.25rem;
`;

const OnboardingPage = () => {
  const { currentUser, logout } = useAuthContext();

  const [displayName, setDisplayName] = useState(currentUser?.displayName?.toString());
  const [photoURL, setPhotoURL] = useState(currentUser?.photoURL?.toString());
  // https://avatars.githubusercontent.com/u/1541741?s=60&v=4

  // const notifications = useNotifications();

  let avatarURL = '';
  if (currentUser?.photoURL) {
    avatarURL = currentUser?.photoURL;
  }

  const handleCompleteProfile = async (e: any) => {
    e.preventDefault();
    if (currentUser) {
      await sendEmailVerification(currentUser)
        .then(() => {
          // notifications.showNotification({
          //   title: 'Email confirmation sent',
          //   message:
          //     'If you do not recieve a confirmation email check your spam folder or request a new email confirmation',
          // });
        })
        .then(() => {
          updateProfile(currentUser, { displayName: displayName, photoURL: photoURL });
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      return;
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  const handleProfileValueChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.currentTarget.id === 'display-name') {
      setDisplayName(event?.currentTarget?.value);
    } else if (event?.currentTarget.id === 'photo-url') {
      setPhotoURL(event?.currentTarget?.value);
    } else {
      return;
    }
  };

  return (
    <Container>
      <h1>Profile</h1>

      <form onSubmit={handleCompleteProfile}>
        <ProfileHeader>
          <Avatar size={100} radius={100} src={avatarURL} />
          <div>
            <h1>
              <span>Welcome </span>
              {currentUser?.displayName ? currentUser?.displayName : currentUser?.email}
            </h1>
            <div>
              <strong>Email:</strong> {currentUser?.email}{' '}
              {currentUser?.emailVerified && <Badge color="green">Email Verified</Badge>}
            </div>
            <div>Display Name: {currentUser?.displayName}</div>
          </div>
        </ProfileHeader>

        {currentUser?.emailVerified && (
          <p>
            <strong>Please complete your profile details and verify your email address</strong>
          </p>
        )}

        <TextInput
          label="Display name"
          placeholder="Enter your prefered name"
          autoComplete="false"
          value={displayName}
          required
          id="display-name"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleProfileValueChanged(e)}
        />
        <br />
        <TextInput
          label="Photo URL"
          placeholder="Enter a link to your profile image"
          description="For example your github profile image."
          autoComplete="false"
          value={photoURL}
          required
          id="photo-url"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleProfileValueChanged(e)}
        />

        <br />
        <Divider variant="dotted" />
        <br />
        <ButtonBar>
          <Button variant="outline" onClick={handleLogout}>
            Cancel and log out
          </Button>
          <Button variant="filled" color="primary" type="submit">
            Complete my profile and send email verifification
          </Button>
        </ButtonBar>
      </form>
    </Container>
  );
};

export default OnboardingPage;
