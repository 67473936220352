import { createContext, FC, useContext, useEffect, useState } from 'react';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword, getIdTokenResult, signInWithEmailAndPassword, User } from 'firebase/auth';
import { auth } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();

// setPersistence(auth, inMemoryPersistence);
interface IAuthContext {
  currentUser?: User | null | undefined;
  currentUserClaims?: {};
  signup: (email: string, password: string) => Promise<void>;
  adminCreateUser: (email: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  authError: FirebaseError | undefined;
  isAdmin: boolean;
  isFaculty: boolean;
  isStudent: boolean;
  isWhanau: boolean;
  userRoles: string[];
  myToken: string;
  updateUserClaim: (uid: string, claims: {}) => Promise<void>;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthService must be used withing AuthServiceProvider');
  }

  return authContext;
};

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider: FC<Props> = ({ children }): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserClaims, setCurrentUserClaims] = useState<{}>();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isFaculty, setIsFaculty] = useState<boolean>(false);
  const [isStudent, setIsStudent] = useState<boolean>(false);
  const [isWhanau, setIsWhanau] = useState<boolean>(false);
  const [myToken, setMyToken] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState<FirebaseError | undefined>();
  let roles: string[] = [];

  const signup: any = async (email: string, password: string) => {
    try {
      setAuthError(undefined);
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      setAuthError(error.message);
    }
  };

  const addStudentUser = httpsCallable(functions, 'addStudentUser');

  const adminCreateUser: any = async (email: string) => {
    await addStudentUser({ email, password: 'password@1' })
      .then(result => {
        // Read result of the Cloud Function.
        /** @type {any} */
        console.log(JSON.stringify(result));
        const data: any = result.data;
        const sanitizedMessage = data.text;
        console.log(`message: ${sanitizedMessage}`);
      })
      .catch(error => {
        // Getting the Error details.
        //const code = error.code;
        const message = error.message;
        //const details = error.details;
        console.log(`error: ${message}`);
      });
  };

  const updateClaims = httpsCallable(functions, 'updateClaims');

  const updateUserClaim: any = async (uid: string, claims: {}) => {
    console.log(uid);
    await updateClaims({ uid: uid, claims: claims })
      .then(result => {
        if (currentUser) getIdTokenResult(currentUser, true);
        console.log(`User: ${uid} claims updated!`);
      })
      .catch(error => {
        // Getting the Error details.
        //const code = error.code;
        const message = error.message;
        //const details = error.details;
        console.log(`error: ${message}`);
      });
  };

  const login: any = async (email: string, password: string) => {
    try {
      setAuthError(undefined);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      setAuthError(error.message);
    }
  };

  const logout: any = () => {
    setAuthError(undefined);
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setLoading(false);
      setCurrentUser(user);
      if (currentUser) {
        currentUser
          .getIdTokenResult()
          .then(idTokenResult => {
            if (!!idTokenResult.claims.admin) {
              setIsAdmin(true);
              roles.push('admin');
            }
            if (!!idTokenResult.claims.faculty) {
              setIsFaculty(true);
              roles.push('faculty');
            }
            if (!!idTokenResult.claims.student) {
              setIsStudent(true);
              roles.push('student');
            }
            if (!!idTokenResult.claims.whanau) {
              setIsWhanau(true);
              roles.push('whanau');
            }
            setCurrentUserClaims(roles);
            setUserRoles(roles);
          })
          .catch(error => {
            console.log(error);
          });

        currentUser.getIdToken().then(idToken => setMyToken(idToken));
      }
      return unsubscribe;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserClaims,
        adminCreateUser,
        signup,
        login,
        logout,
        authError,
        isAdmin,
        isFaculty,
        isStudent,
        isWhanau,
        userRoles,
        myToken,
        updateUserClaim,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
